<template>
  <v-container fluid>

    <v-app-bar elevation="0" app>
      <v-spacer/>
      <div>

      </div>

      <v-dialog
          v-model="dialog"
          width="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              depressed
              color="secondary"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5">
            Выход
          </v-card-title>

          <v-card-text>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>

            <v-btn
                color="primary"
                text
                @click="dialog = false"
            >
              Закрыть
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="logout()"
            >
              Выйти
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="m-0 p-0">

      <!-- Provides the application the proper gutter -->
      <v-container class="m-0 p-0" fluid>

        <!-- If using vue-router -->
        <router-view/>
      </v-container>
    </v-main>

    <!--    <v-footer app>
          &lt;!&ndash; &ndash;&gt;
        </v-footer>-->
  </v-container>
</template>

<script>

export default {
  name: "NavContainer",
  components: {},
  data: () => ({
    dialog: false
  }),
  methods: {
    logout() {
      localStorage.clear();
      this.$store.state.user = null;
      this.$router.push({name: 'Login'})
    }
  }
}
</script>

<style scoped>

</style>
